<template>
  <div>
    <v-row no-gutters>
      <v-col class="col-12 content-header">
        <template v-if="success === true">
          <header>
            <h1 class="content-title">
              Your email has been verified
            </h1>
          </header>
          <p class="content-subtitle">
            You can now sign in with your new account
          </p>
          <v-btn
            link
            :to="{ name: 'signIn' }"
            x-large
            color="primary"
            class="mt-8"
          >
            Sign in
          </v-btn>
        </template>

        <template v-if="success === false">
          <header>
            <h1 class="content-title">
              Try verifying your email again
            </h1>
          </header>
          <p class="content-subtitle">
            Your request to verify your email has expired or the link has
            already been used
          </p>
          <v-btn
            link
            :to="{ name: 'signIn' }"
            x-large
            color="primary"
            class="mt-8"
          >
            Home
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from '@/firebase'

export default {
  name: 'Action',
  data() {
    return {
      success: null
    }
  },
  mounted() {
    this.$store.dispatch('app/loading', true)

    firebase
      .auth()
      .applyActionCode(this.$route.query.oobCode || '')
      .then(() => {
        this.success = true
        this.$store.dispatch('app/loading', false)
      })
      .catch(() => {
        this.success = false
        this.$store.dispatch('app/loading', false)
      })
  },
  head: {
    title: {
      inner: 'Email verification'
    }
  }
}
</script>

<style scoped>
header {
  margin: 1rem 0;
}
</style>